import React, { useEffect, useState } from "react";

import { Col, ProgressBar, Row } from "react-bootstrap";

//Just for testing
import NoProfilePic from "../../assets/no_profile_pic.png";

//Import user profile api
import { useGetUserInfo } from "../../utils/api/functions/members";

//Import create post api
import { createPost } from "../../utils/api/functions/advancedActivityFeeds";
import { createPostUploadVideo } from "../../utils/api/functions/advancedVideos";
//Import datepicker
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ReactTooltip from "react-tooltip";

//Emoji picker
import Picker from "emoji-picker-react";

//Import Attachment Options
import {
  AddLink,
  AddPhoto,
  AddVideo,
  FeelingActivity,
  TagAFriend
} from "./postAttachments/attachments";

//Date Parsing functions
import { getReadableDate } from "../../utils/parsing";

import { toast } from "react-toastify";
import useProgressBarStore from "../../utils/stores/progressBarStore";
import { LoadingButton } from "../buttons/Buttons";


const EmojiPicker = React.memo(Picker); 

const CreatePostActions = ({ postText, callMutate = null, ...props }) => {
  const [scheduleDate, setScheduleDate] = useState();

  const [taggedFriends, setTaggedFriends] = useState([]);

  const [selectedActivity, setActivity] = useState();

  const [link, setLink] = useState();

  const [images, setImages] = useState([]);

  const [videos, setVideos] = useState();

  const [authView, setAuthView] = useState("everyone");

  const [isPosting, setIsPosting] = useState(false);

  const {progress, setProgress} = useProgressBarStore(); 

 

  const subject_type = props.subject_type ? props.subject_type : "";
  const subject_id = props.subject_id ? props.subject_id : "";

  //Handle friend tagging selection
  const handleTagFriend = (user_id) => {
    let new_arr = [...taggedFriends];
    new_arr.push(user_id);

    setTaggedFriends(new_arr);
  };
  const handleRemoveTagFriend = (user_id) => {
    let new_arr = [...taggedFriends];
    let position = 0;

    for (let i = 0; i < new_arr.length; i++) {
      if (new_arr[i].id === user_id) {
        position = i;
        break;
      }
    }

    new_arr.splice(position, 1);

    setTaggedFriends(new_arr);
  };

  //Handle selection from feelings / activites menu
  //@params: object
  // {
  //     title: String,
  //     id: int,
  // }
  const handleActivitySelection = (activity_feeling) => {
    setActivity(activity_feeling);
  };
  const handleActivityDelete = () => {
    setActivity(null);
  };

  //Handle add link
  const handleAddLink = (new_link) => {
    setLink(new_link);
  };
  const handleDeleteLink = () => {
    setLink(null);
  };

  //Handle Add Photo
  const handleAddPhoto = (new_images) => {
    let new_arr = [...images];
    new_images = [...new_images];
    let image_objs = new_images.map((item) => {
      return {
        file: item,
        preview_url: URL.createObjectURL(item),
      };
    });
    new_arr.push(...image_objs);

    setImages(new_arr);
  };
  const handleRemovePhoto = (index) => {
    let new_arr = [...images];

    new_arr.splice(index, 1);
    setImages(new_arr);
  };

  //Handle Add Video
  const handleAddVideo = (new_videos, thumbnail) => {
    let video_obj = {
      ...new_videos,
      thumbnail: thumbnail,
    };

    setVideos(video_obj);
  };
  const handleRemoveVideo = () => {
    setVideos(null);
  };

  //Handle Auth View Select
  const handleAuthView = (e) => {
    setAuthView(e.target.value);
  };

  //Handle Scheduled date
  const handleScheduleDate = (date) => {

    setScheduleDate(date);
  };
  const handleRemoveScheduleDate = () => {
    setScheduleDate(null);
  };

  const sendPost = async () => {
    setIsPosting(()=>true);

    //@TODO: Validate post data
    toast.info("Creating post...");
    let form = new FormData();
    let post_attach = 0;
    let attach_type = "";

    let api_function = createPost;

    //Start appending values
    form.append("body", postText);

    //Dont allow empty posts 
    if (!postText) {
      toast.error("Post cannot be empty"); 
      setIsPosting(false);
      return; 
    }

    form.append("language", "en");
    form.append("auth_view", authView);

    if (taggedFriends.length > 0) {
      let tagged_friends = taggedFriends
        .map((item) => {
          return item.id;
        })
        .join(",");
      form.append("toValues", tagged_friends);
    }

    //Set Schedule Date
    if (scheduleDate) {
      //Ex date value: '2022-06-01 16:40:27'
      const unparsed_date = new Date(scheduleDate);
      const parsed_date =
        ("00" + (unparsed_date.getMonth() + 1)).slice(-2) +
        "/" +
        ("00" + unparsed_date.getDate()).slice(-2) +
        "/" +
        unparsed_date.getFullYear() +
        " " +
        ("00" + unparsed_date.getHours()).slice(-2) +
        ":" +
        ("00" + unparsed_date.getMinutes()).slice(-2) +
        ":" +
        ("00" + unparsed_date.getSeconds()).slice(-2) + " " + new Date().toString().match(/([A-Z]+[\+-][0-9]+)/)[1];
      form.append("schedule_time", parsed_date);
    }
    if (link) {
      post_attach = 1;
      attach_type = "link";
      form.append("uri", link);
    }

    if (selectedActivity) {
      form.append(
        "composer",
        JSON.stringify({
          feeling: {
            parent: selectedActivity.parent.id,
            child: selectedActivity.child.id,
          },
        }),
      );
    }

    form.append("type", attach_type);
    form.append("post_attach", post_attach);

    //Set subject id and subject type
    form.append("subject_type", subject_type);
    form.append("subject_id", subject_id);

    //Append attachments
    if (images.length > 0) {
      //If the attachment is image(s)
      post_attach = 1;
      form.append("type", "photo");

      if (images.length > 1) {
        for (let i = 0; i < images.length; i++) {
          form.append(`filedata${i}`, images[i].file, images[i].file.name);
        }
      } else {
        form.append("photo", images[0].file, images[0].file.name);
      }
    } else if (videos) {
      //If the attachment is a video
      post_attach = 1;
      attach_type = 3;
      api_function = createPostUploadVideo;
      form.set("post_attach", 1);
      form.set("type", 3);
      form.delete("subject_type");
      form.append("filedata", videos.file);
      form.append("REACT_APP", "1");
    }
    console.log(api_function)
    setProgress(100)
    await api_function(form, (percent)=>{
      setProgress(percent)
    });
    if (callMutate) {
      callMutate();
    }

    toast("Post Created");
    setProgress(0);
    setIsPosting(()=>false);
  };

  return (
    <Col className="create-post-actions-container" {...props}>
      <Row className="create-post-tagged-friends">
        {taggedFriends.map((item) => {
          return (
            <div className="create-post-tagged-friends-item">
              <span
                id="tagged-friends-delete"
                onClick={() => {
                  handleRemoveTagFriend(item.id);
                }}
              >
                x
              </span>
              <span id="tagged-friend-label">{item.label}</span>
            </div>
          );
        })}
      </Row>
      {selectedActivity ? (
        <Row className="create-post-selected-activity-feeling">
          <span>
            <span
              id="create-post-selected-activity-feeling-delete"
              onClick={handleActivityDelete}
            >
              x
            </span>
            — <img src={`https://xapit.com${selectedActivity.parent.url}`} />{" "}
            {selectedActivity.parent.title} {selectedActivity.child.title}
          </span>
        </Row>
      ) : null}

      {link ? (
        <Row className="create-post-added-link">
          <span>
            <span className="x-delete" onClick={handleDeleteLink}>
              x
            </span>
            <a href={link} target="_blank">
              {link}
            </a>
          </span>
        </Row>
      ) : null}
      {images.length > 0 ? (
        <div className="create-post-upload-image-preview">
          {images.map((item, index) => {
            return (
              <div>
                <img src={item.preview_url} />
                <span
                  className="image-delete-x"
                  onClick={() => {
                    handleRemovePhoto(index);
                  }}
                >
                  x
                </span>
              </div>
            );
          })}
        </div>
      ) : null}
      {videos ? (
        <div className="create-post-video-preview">
          <div>
            <span className="image-delete-x" onClick={handleRemoveVideo}>
              x
            </span>
            <video controls autoPlay={false}>
              <source src={videos.preview_url}></source>
            </video>
          </div>
        </div>
      ) : null}
      {scheduleDate ? (
        <div className="create-post-scheduled-date-preview">
          <span>
            <span className="x-delete" onClick={handleRemoveScheduleDate}>
              x
            </span>
            {getReadableDate(scheduleDate)}
          </span>
        </div>
      ) : null}

      <Row className="create-post-actions-options">
        <TagAFriend add={handleTagFriend} currentlySelected={taggedFriends} />
        <FeelingActivity add={handleActivitySelection} />
        <AddLink handleSubmit={handleAddLink} />
        {!videos && <AddPhoto handleSubmit={handleAddPhoto} />}

        {/* <AddSticker /> */}
        {!(images.length > 0) && <AddVideo handleSubmit={handleAddVideo} />}
      </Row>
      <Row className="create-post-actions">
        <div className="create-post-actions-datepicker-container">
          <DatePicker
            selected={scheduleDate}
            onChange={handleScheduleDate}
            customInput={
              <button className="create-post-actions-datepicker-btn">
                <FontAwesomeIcon icon={faCalendar} />
              </button>
            }
            minDate={new Date()}
            filterTime={(time) => {
              const currentDate = new Date();
              const selectedDate = new Date(time);
          
              return currentDate.getTime() < selectedDate.getTime();
            }}
            showTimeSelect
            timeIntervals={5}
          />
        </div>
        <select onChange={handleAuthView} value={authView}>
          <option value="everyone">Everyone</option>
          <option value="friends">Friends Only</option>
          <option value="onlyme">Only Me</option>
        </select>
        {/* <button className="create-post-actions-share-btn" onClick={sendPost}>
                    Share
                </button> */}
        <LoadingButton
          className="create-post-actions-share-btn"
          onClick={sendPost}
          loading={isPosting}
          text="Share"
        />
      </Row>
      
      <ProgressBar now={progress}/> 
      
      
    </Col>
  );
};

const CreatePost = ({ callMutate, ...props }) => {
  const { data } = useGetUserInfo({
    user_id: window.localStorage.getItem("user_id"),
  });
  const [userData, setUserData] = useState(NoProfilePic);
  const [postText, setPostText] = useState("");
  const [pickerDisplay, setPickerDisplay] = useState(false);

  const onEmojiClick = (event, emojiObject) => {
    setPostText(postText + emojiObject.emoji);
  };
  const togglePicker = () => {
    setPickerDisplay(!pickerDisplay);
  };

  useEffect(() => {
    const parseData = () => {
      if (data && data.status_code === 200) {
        setUserData(data.body.response);
      }
    };
    parseData();
  }, [data]);

  const postOnChange = (e) => {
    setPostText(e.target.value);
  };

  const tx = document.getElementsByTagName("textarea");
  for (let i = 0; i < tx.length; i++) {
    tx[i].setAttribute(
      "style",
      "height:" + tx[i].scrollHeight + "px;overflow-y:auto;",
    );
    tx[i].addEventListener("input", OnInput, false);
  }

  function OnInput() {
    this.style.height = 0;
    this.style.height = this.scrollHeight + "px";
  }

  return (
    <Col
      className={
        props.className
          ? props.className + "create-post col"
          : "create-post col"
      }
    >
      <div className="create-post-title">
        <h4>{props.title}</h4>
      </div>
      <Row className="create-post-form">
        <div className="create-post-profile-pic-box-sizing">
          <img src={userData.image_icon} className="create-post-profile-pic" />
        </div>
        <div className="create-post-box">
          <textarea
            className="create-post-form-text"
            type="textarea"
            placeholder="Post Something..."
            value={postText}
            onChange={postOnChange}
            maxLength={1000}
          />
          {postText.length > 0 ? <div>{postText.length}/1000</div> : null}
          <p
            onClick={togglePicker}
            className="emoji-picker-toggle"
            data-tip
            data-for="emoji-picker-tip"
          >
            😃
          </p>
          <ReactTooltip id="emoji-picker-tip" place="top" effect="solid">
            Add an emoji
          </ReactTooltip>
          <div className="emoji-picker-container">
            <div
              className="emoji-picker-wrapper"
              style={{
                display: pickerDisplay ? "block" : "none",
              }}
            >
              <EmojiPicker 
                onEmojiClick={onEmojiClick}
                disableAutoFocus
                
                />
              {/* <Picker
                
              /> */}
            </div>
          </div>
        </div>
      </Row>
      <CreatePostActions
        postText={postText}
        callMutate={callMutate}
        {...props}
      />
    </Col>
  );
};

export default React.memo(CreatePost);
