import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { likeFeedOrComment, postDeleteFeed, postHideFeedItem, unlikeFeedOrComment, usePaginateAdvancedActivityFeeds } from "../../../utils/api/functions/advancedActivityFeeds";
import { FeedContext } from "../../../utils/context/feedContext";
import LoadingPostItem from "../../loading-placeholders/LoadingPostItem";
import VideoDisplay from "../../videos/VideoDisplay";
import Share from "../Share";
import VirtualizedFeedList from "./VirtualizedFeedList";


const Feedv3 = ({
  feed_params = {},
  CreatePostComponent = null,
  can_post = true,
  ...props
}) => {
  //const {data, mutate, isLoading} = useGetAdvancedActivityFeeds(feed_params);

  //Default Feed Params
  if (!feed_params?.limit) {
    feed_params.limit = 10;
  }

  // if(!feed_params?.feed_type){

  //     feed_params.filter_type = "all";
  // }

  const { data, isLoading, mutate, size, setSize } =
    usePaginateAdvancedActivityFeeds(feed_params);

  const [postsData, setPostsData] = useState();
  const [postsError, setPostsError] = useState();

  const [isLoadingMore, setIsLoadingMore] = useState(false);

 

  const [showShare, setShowShare] = useState(false);
  const [shareData, setShareData] = useState({});


  const [attachmentLightboxData, setAttachmentLightboxData] = useState({}); 
  const [showAttachmentLightbox, setShowAttachmentLightbox] = useState(false); 

  //Set CreatePostComponent
  const CreatePostEl = () => {
    if (!can_post) return null;
    if (CreatePostComponent) {
      if (feed_params.subject_type && feed_params.subject_id) {
        return (
          <CreatePostComponent
            callMutate={mutate}
            subject_type={feed_params.subject_type}
            subject_id={feed_params.subject_id}
          />
        );
      }

      return <CreatePostComponent callMutate={mutate} />;
    }
    return null;
  };
  const likeFn = async (action_id, updated_object) => {
    let postData = {
      action_id: action_id,
    };
    let formData = new FormData();
    for (let key in postData) {
      formData.append(key, postData[key]);
    }
    let res = await likeFeedOrComment(formData);
    if (res.status_code == 204 || res.status_code == 200) {
      return updated_object;
    } else {
      throw new Error("Invalid API Response");
    }
  };
  const unlikeFn = async (action_id, updated_object) => {
    let postData = {
      action_id: action_id,
    };
    let formData = new FormData();
    for (let key in postData) {
      formData.append(key, postData[key]);
    }
    let res = await unlikeFeedOrComment(formData);
    if (res.status_code == 204 || res.status_code == 200) {
      return updated_object;
    } else {
      throw new Error("Invalid API Response");
    }
  };
  const handleLike = async (action_id) => {
    let updateObject = new Array(...data);

    for (let i = 0; i < updateObject.length; i++) {
      let page_data = updateObject[i];
      for (let x = 0; x < page_data.body.data.length; x++) {
        let item = page_data.body.data[x];
        if (item.feed.action_id == action_id) {
          item.is_like = 1;
          break;
        }
      }
    }

    await mutate(likeFn(action_id, updateObject), {
      optimisticData: updateObject,
      rollbackOnError: true,
      populateCache: true,
      revalidate: false,
    });
  };
  const handleUnlike = async (action_id) => {
    let updateObject = new Array(...data);

    for (let i = 0; i < updateObject.length; i++) {
      let page_data = updateObject[i];
      for (let x = 0; x < page_data.body.data.length; x++) {
        let item = page_data.body.data[x];
        if (item.feed.action_id == action_id) {
          item.is_like = 0;
          break;
        }
      }
    }

    await mutate(unlikeFn(action_id, updateObject), {
      optimisticData: updateObject,
      rollbackOnError: true,
      populateCache: true,
      revalidate: false,
    });
  };
  const handleShowShare = (data = null) => {

    setShowShare(true);
    if (data) {
      setShareData(() => data);
    }
  };
  const handleHideShare = () => {

    setShowShare(false);
  };
  const postHandles = {
    handleLike: handleLike,
    handleUnlike: handleUnlike,
    handleShowShare: handleShowShare,
  };
  const _loadMoreItemsA = async () => {
    if (isLoading) return;
    setIsLoadingMore(true);
    await setSize(size + 1);
    setIsLoadingMore(false);
  };
  useEffect(() => {
    const parseData = () => {
      setPostsError(""); // Clear any previous errors

      if (data) {

        let fetched_posts = [];

        for (let i = 0; i < data.length; i++) {
          if (data[i].status_code === 200) {
            //Set loading more false if there is no more data 
            if(data[i]?.body?.activityCount === 0){
              setIsLoadingMore(false); 
            }

            //Sort comment previews into post data
            let parsed_actions = [];
            let comment_preview = data[i].body.comment_preview;
            if (data[i]?.body?.data?.length > 0) {
              parsed_actions = data[i].body.data.map((val) => {
                let current_action_id = val.feed.action_id;
                let comment_data = {};
                comment_preview.map((val) => {
                  if (val.action_id === current_action_id) {
                    comment_data = val;
                  }
                });

                val.comment_data = comment_data;
                return val;
              });
            }

            fetched_posts.push(...parsed_actions);
          } else if (data[i].status_code === 401) {
            setPostsError(data[i].error_code);
          }
        }
        if (fetched_posts.length === 0) return; 
        setPostsData({posts: fetched_posts})
      }
    };
    parseData();
  }, [data]);

  
  if (isLoading) { //Changed from !postsData && isLoading
    return (
      <>
        <CreatePostEl />
        <LoadingPostItem />
      </>
    );
  }

  if (postsError === "unauthorized") {
    return (
      <div style={{ textAlign: "center", fontWeight: "bold" }}>
        You don't have permission to view this feed.
      </div>
    );
  }

  if (!postsData || postsData.length === 0) {
    return (
      <>
        <CreatePostEl />
        <div className="posts-feed col">
          <div
            style={{
              textAlign: "center",
              paddingTop: "1.5rem",
            }}
          >
            No posts here yet...
          </div>
        </div>
      </>
    );
  }

  const handleUpdate = () => {
    mutate();
  };
  const handleDelete = async (action_id) => {
    let res = await postDeleteFeed({ action_id: action_id });

    if (res.status_code === 204) {
      toast.success("Post deleted successfully");
    }
    mutate();
  };
  const handleHidePost = async(action_id, type) => {
    let res = await postHideFeedItem({id: action_id, type: 'activity_action'})

    if(res.status_code === 204){
      toast.success("Post hidden successfully")
    }
    mutate(); 

  };


  const handleShowAttachment = ({...params}) => {
    setAttachmentLightboxData(()=>params)
    setShowAttachmentLightbox(true); 
  }
  const handleHideAttachment = () => {
    setShowAttachmentLightbox(false); 
    setAttachmentLightboxData(()=>{})
  }

  return (
    <>
      <FeedContext.Provider
        value={{ handleUpdate, handleDelete, handleHidePost, handleShowAttachment }}
      >
        <div className="posts-feed col">
          <VirtualizedFeedList
            postData={[{}].concat(postsData.posts)}
            handles={postHandles}
            loadMoreItems={_loadMoreItemsA}
            isLoadingMore={isLoadingMore}
            CreatePostEl={CreatePostEl}
            _isLoading={isLoading}
            {...props}
          />
          {/* SHOW LIGHTBOX FOR ATTACHMENTS */}
          
          <VideoDisplay 
            show={showAttachmentLightbox}
            onHide={handleHideAttachment}
            {...attachmentLightboxData}
          />
           
          <Share show={showShare} onHide={handleHideShare} data={shareData} />
        </div>
      </FeedContext.Provider>
    </>
  );
};
export default Feedv3;