
import React, { useContext } from "react";
import { FeedContext } from "../../../utils/context/feedContext";
import PageAttachment from "./PageAttachment";
import { PostItem } from "./PostItem";

const PostItemAttachment = ({ attachment, owner_data }) => {


    const {handleShowAttachment} = useContext(FeedContext); 
  
    if (attachment?.attachment_type?.includes("activity_action")) {
      return (
        <PostItem
          postData={attachment.shared_post_data[0]}
          key={`post-item-attachment-${attachment.attachment_id}`}
          handles={{}}
          actionsOff={true}
          commentsOff={true}
        />
      );
    }
  
    if (attachment?.attachment_type?.includes("photo")) {
      console.log("attachment: ", attachment);
      return (
        <>
          <img
            onClick={() => {
              handleShowAttachment({
                video_data: attachment, 
                type: "album_photo", 
                subject_type: "album_photo", 
                subject_id: attachment.photo_id, 
                navButtons: false, 
                owner_data: owner_data, 
                attachment: true 
              })
            }}
            style={{
              cursor: "pointer"
            }}
            src={attachment.image_medium || attachment.image_main.src}
            className="post-item-attachment-image"
            alt=""
            loading="lazy"
          ></img>
          {/* <VideoDisplay 
            show={showLightbox}
            onHide={handleHide}
            video_data={attachment}
            type={"album_photo"}
            subject_type="album_photo"
            subject_id={attachment.photo_id}
            navButtons={false}
            owner_data={owner_data}
            attachment={true}
          /> */}
        </>
        
      );
    }
    if (attachment?.attachment_type?.includes("video")) {
       
  
  
      if(attachment.attachment_video_type === 1 || attachment.attachment_video_type === 6) {
  
        let video_url = attachment.attachment_video_url; 
  
        if(attachment.attachment_video_type === 1) {
          const queryParams = new URLSearchParams(video_url);
          queryParams.set("autoplay", "0"); 
          video_url = decodeURIComponent(queryParams.toString()); 
        }
        if(attachment.attachment_video_type === 6) {
          const parser = new DOMParser(); 
          let parse_iframe = parser.parseFromString(video_url, "text/html");
          let iframe_src = parse_iframe.querySelector("iframe").src;
          const queryParams = new URLSearchParams(iframe_src); 
          queryParams.get("autoplay") ? queryParams.set("autoplay", "0") : queryParams.append("autoplay", "0");
          video_url = queryParams.toString(); 
        }
  
        return (
          <div
            className="post-item-attachment-video"
          >
            {
              attachment.attachment_video_type === 6 ? (
                <iframe 
                  srcDoc={attachment.attachment_video_url}
                  loading="lazy"
  
                />
              ) : (
                <iframe 
                  src={`https://`+video_url}
                  loading="lazy"
  
                />
              )
            }
          </div>
        )
      }
  
      return (
        <div className="post-item-attachment-video">
          <div className="play-video-button"></div>
          <video autoPlay={false} onClick={(e)=>{e.preventDefault(); 
            handleShowAttachment({
              video_data: attachment, 
              type: "video", 
              subject_type: "video", 
              subject_id: attachment.photo_id, 
              navButtons: false, 
              owner_data: owner_data, 
              attachment: true
            })
          }}
            style={{
              cursor: "pointer"
            }}
          >
            <source src={attachment.attachment_video_url} />
          </video>
          {/* <VideoDisplay 
            show={showLightbox}
            onHide={handleHide}
            video_data={attachment}
            type={"video"}
            subject_type="video"
            subject_id={attachment.photo_id}
            navButtons={false}
            owner_data={owner_data}
            attachment={true}
          /> */}
        </div>
      );
    }
    if (attachment?.attachment_type?.includes("link")) {
      return (
        <div className="post-item-attachment-link">
          <a href={attachment.uri} target="_blank" rel="noreferrer">
          <div className="post-item-attachment-link-image">
            <img src={attachment.image_main.src} alt="" />
          </div>
          <div className="post-item-attachment-link-content">
            <div className="post-item-attachment-link-title">
                {attachment.title}
            </div>
          </div>
          </a>
        </div>
      );
    }
    if (["sitepage_page", "group", "event"].includes(attachment?.attachment_type)) {
      return(
        <PageAttachment attachment={attachment} />
      )
    }
  
    return null;
  };


export default PostItemAttachment;