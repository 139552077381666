import React, { createContext, useContext, useEffect, useState } from 'react';
import { MessageBox, NewMessage } from '../../../components/widgets/MessagesWidget';
import { useGetMessages } from '../../api/functions/messages';

const MessagesContext = createContext(); 

export const MessagesProvider = ({children}) => {
    const [show, setShow] = useState(false); 
    const [messages, setMessages] = useState([]); 
    const [activeMessage, setActiveMessage] = useState(null); 
    const { data, mutate } = useGetMessages({}); 
    const [showNewMessageForm, setShowNewMessageForm] = useState(false); 

    const showMessageBox = () => {
        setShow(true); 
    }
    const hideMessageBox = () => {
        setShow(false); 
    }
    const openMessage = (message, showBox=true) => {
        if(showBox) showMessageBox(); 
        setActiveMessage(message);     
    }
    const closeMessage = () => {
        setActiveMessage(null); 
    }
    const openNewMessage = (user_data) => {
        setShowNewMessageForm(user_data); 
    }   
    const closeNewMessage = (message) => {
        setShowNewMessageForm(false);  
        if(message) openMessage(message); 
        
    }
    const updateMessages = () => {
        mutate(); 
    }

    useEffect(() => {
        if (data && data.body && data.body.response) {
            console.log("MESSAGES: ", data.body.response)
            setMessages(data.body.response); 
        }
    })

    return (
        <MessagesContext.Provider value={{show, showMessageBox, hideMessageBox, openMessage, closeMessage, openNewMessage, closeNewMessage, messages, activeMessage, setActiveMessage, showNewMessageForm}}>
            {children}
            <MessageBox/>
            <NewMessage />
        </MessagesContext.Provider>
    )

}; 

export const useMessages = () => {
    const { show, showMessageBox, hideMessageBox, openMessage, closeMessage, messages, activeMessage, setActiveMessage, openNewMessage, closeNewMessage, showNewMessageForm } = useContext(MessagesContext);
    return { show, showMessageBox, hideMessageBox, openMessage, closeMessage, messages, activeMessage, setActiveMessage, openNewMessage, closeNewMessage, showNewMessageForm }; 
}
