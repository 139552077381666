import React from "react";
import { Facebook } from "react-content-loader";

const LoadingPostItem = (props) => {
  return (
    <Facebook
      {...props}
      style={{
        backgroundColor: "white",
        padding: "1rem",
        borderRadius: "7px",
        width: "100%",
      }}
    />
  );
};

export default React.memo(LoadingPostItem);
