import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { deleteSitestory, useGetSitestories, useViewStory } from '../../../utils/api/functions/sitestories';
import { setStoryWidgetSeen } from '../../../utils/cookies';
import { ReportModal } from '../ReportModal';

const StoryOptions = ({story, onHide=()=>{}}) => {
    const [showMenu, setShowMenu] = useState(false); 
    const [showReport, setShowReport] = useState(false); 
    const menuRef = useRef(null); 
    
    const {data, mutate} = useGetSitestories(); 

    const handleClick = () => {
        setShowMenu((prev) => !prev); 
    }
    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if(menuRef.current && !menuRef.current.contains(event.target)) {
    //             setShowMenu(false); 
    //         }
    //     }
    //     document.addEventListener('click', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('click', handleClickOutside); 
    //     }
    // }, [])

    const handleOpenReport = () => {
        setShowReport(true); 
    }

    useEffect(() => {
        console.log("Story options (story data): ", data); 
    }, [data])

    const isOwner = String(story?.owner_id) === localStorage.getItem("user_id"); 

    const handleDelete = async() => {
        const res = await deleteSitestory(story.story_id);
        if(res.status_code === 204) {
            toast.success("Story deleted successfully"); 
            let optimisticData = {...data}; 
            for(let i = 0; i<optimisticData.body.response.length; i++) {
                if(optimisticData.body.response[i].owner_id === story.owner_id) {
                    optimisticData.body.response[i].total_stories -= 1; 
                    break; 
                }
            }
            mutate(optimisticData, false); 
            onHide(); 
        }
        else {
            toast.error("An error occurred while deleting the story");
            mutate(); 
        }
    }; 

    return(
        <div className="story-options">
            <FontAwesomeIcon icon={faEllipsisVertical} onClick={handleClick}/>
            <div className={`story-options-menu ${showMenu ? "active" : ""}`} ref={menuRef}>
                {
                    isOwner ? (
                        <div className="menu-item" onClick={handleDelete}>
                            Delete
                        </div>
                    ) : (null)
                }
                <div className="menu-item" onClick={handleOpenReport}>
                    Report
                </div>
            </div>
            <ReportModal 
                show={showReport}
                onHide={() => setShowReport(false)}
                type="sitestories_story"
                id={story.story_id}
            />
        </div>
    )
}

const StoryPlayer = ({ stories, onHide = () => {} }) => {
    const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
    const storyRef = useRef(null);

    const currentStory = stories[currentStoryIndex];

    // Function to handle moving to the next story
    const nextStory = () => {
        setCurrentStoryIndex((prevIndex) => (prevIndex + 1) % stories.length);
    };

    // Autoplay logic for videos and images
    useEffect(() => {
        if (!currentStory) return;

        let timer;
        if (currentStory.code === "jpg") {
            // For images, set a timer of 5 seconds
            timer = setTimeout(nextStory, 5000);
        } else {
            const videoElement = storyRef.current;
            if (videoElement) {
                videoElement.addEventListener("ended", nextStory);
                videoElement.play().catch((error) => console.log(error));
            }
            // Cleanup video event listener on unmount
            return () => {
                if (videoElement) {
                    videoElement.removeEventListener("ended", nextStory);
                }
            };
        }

        // Cleanup timer for images
        return () => clearTimeout(timer);
    }, [currentStoryIndex]);

    return (
        <div className="story-widget-player">
            <div className="story-progress-container">
                {stories.map((_, index) => (
                    <div
                        key={index}
                        className={`story-progress-bar ${index === currentStoryIndex ? "active" : ""}`}
                        style={{
                            animationDuration: `${currentStory?.code === "jpg" ? 5 : storyRef.current?.duration || 5}s`,
                            animationPlayState: index === currentStoryIndex ? "running" : "paused",
                        }}
                    />
                ))}
            </div>
            {currentStory.code === "jpg" ? (
                <img
                    src={currentStory.videoUrl}
                    alt="Story"
                    onClick={nextStory}
                />
            ) : (
                <video
                    controls
                    autoPlay
                    ref={storyRef}
                    src={currentStory.videoUrl}
                    onClick={nextStory}
                    playsInline
                />
            )}
            <StoryOptions story={stories[currentStoryIndex]} onHide={onHide} />
        </div>
    );
};


const StoryView = ({ show, story_id, onHide }) => {
    const {data, error} = useViewStory(story_id); 
    const [stories, setStories] = useState([]); 

    useEffect(() => {
        console.log("Story view: ", data); 

        if(data?.body) {
            setStories(() => [...data.body]); 
        }
    }, [data]); 

    useEffect(() => {
        if (!show) return;
        // add logic to set story as viewed
        setStoryWidgetSeen(story_id); 
    }, [show])

    return(
        <Modal
            show={show}
            size="lg"
            centered
            variant="dark"
            onHide={onHide}
        >
            <Modal.Header closeButton={true}>
            </Modal.Header>
            <Modal.Body>
                {stories.length > 0 && <StoryPlayer stories={stories} onHide={onHide}/> } 
            </Modal.Body>
        </Modal>
    )
}

export default StoryView; 