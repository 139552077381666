import queryString from "query-string";
import useSWR from "swr";
import oauthConfig from "../../../oauth.config";
import api_config from "../../../settings/api.config";
import { fetcher } from "../handler";
const hostname = api_config.hostname;

export const useGetAds = (values) => {
  return useSWR(
    [
      hostname + "/communityads/index/index?limit=2&type=2&placementCount=1",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useGetOwnersAds = (values) => {
  return useSWR(
    [
      hostname + "/communityads/index/getcampaigns",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const useGetPackages = (values) => {
  return useSWR(
    [
      hostname + "/communityads/index/getpackages",
      {
        method: "GET",
        body: queryString.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
};

export const postEditCampaign = (values) => {
  return fetcher(
    [
      hostname + `/communityads/index/editcampaign?id=${values.id}&name=${values.name}`, //check url
      {
        method: "POST",
        body: values, 
        headers: {
          "Accept": "application/json", 
          "oauth_consumer_key": oauthConfig.oauth_consumer_key, 
          "oauth_consumer_secret": oauthConfig.oauth_consumer_secret
        }
      }
    ], false, true
  )
}; 

export const postCreateCampaign = (values) => {
  return fetcher(
    [
      hostname + `/communityads/index/create?` + queryString.stringify(values),
      {
        method: "POST",
        body: values, 
        headers: {
          "Accept": "application/json", 
          "oauth_consumer_key": oauthConfig.oauth_consumer_key,
          "oauth_consumer_secret": oauthConfig.oauth_consumer_secret
        }
      }
      
    ], false, true
  )
}

export const postDeleteCampaign = (id) => {
  return fetcher(
    [
      hostname + `/communityads/index/deletecampaign?id=${id}`, //check url
      {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "oauth_consumer_key": oauthConfig.oauth_consumer_key,
          "oauth_consumer_secret": oauthConfig.oauth_consumer_secret
        }
      }
    ], false, true
  )
};

export const useAdStatistics = (type, start, end, chunk, ad_id, adcampaign_id) => {

  const key = (() => {
    if (!start || !end) {
      return null;  
    }
    if(ad_id) {
      return hostname + `/communityads/index/statistics?type=${type}&start=${start}&end=${end}&chunk=${chunk}&ad_id=${ad_id}`;
    }
    else if (adcampaign_id) {
      return hostname + `/communityads/index/statistics?type=${type}&start=${start}&end=${end}&chunk=${chunk}&adcampaign_id=${adcampaign_id.join('_')}`;
    }
  })
  return useSWR(
    [
      key(),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          oauth_consumer_key: oauthConfig.oauth_consumer_key,
          oauth_consumer_secret: oauthConfig.oauth_consumer_secret,
        },
      },
    ],
    fetcher,
  );
}

export const postUpdateClickCount = (userad_id) => {
  return fetcher([
    hostname + `/communityads/update-click-count/${userad_id}`,
    {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "oauth_consumer_key": oauthConfig.oauth_consumer_key,
        "oauth_consumer_secret": oauthConfig.oauth_consumer_secret
      }
    }
  ], false, true);
};