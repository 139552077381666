import React, { Suspense, useEffect } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";

// Routing Imports
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import routesConfig from "./settings/routes";
import useAuth, { AuthProvider } from "./utils/authentication";

//Import styling
import "bootstrap/scss/bootstrap.scss";
import { CookiesProvider } from "react-cookie";
import "./styles/sass/Main.scss"; //Default light color scheme styling

//Notifications Imports
import { Bounce, ToastContainer } from "react-toastify";

import { SWRConfig } from "swr";

import { cleankeyMiddleware } from "./utils/middleware/swrMiddleware";
import ScrollToTop from "./utils/scrollToTop";

//Disable console.log in production mode 
if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}


function RequireAuth({ children, layout }) {
  const { authed } = useAuth();
  if (authed !== true) {
    return <Navigate to="/login" replace></Navigate>;
  }

  const Layout = React.cloneElement(layout, { children: children });
  return layout ? Layout : children;
}

const DocumentTitle = () => {
  const location = useLocation();

  useEffect(() => {
    const main_path = "/" + location.pathname.split("/")[1];
    let route_title = "";
    routesConfig.some((item) => {
      if (!item.path) return false;
      const test_path = item.path.includes(":id")
        ? "/" + item.path.split("/")[1]
        : item.path;
      if (test_path === main_path) {
        route_title = item.document_title ? item.document_title : "";
        return true;
      }
      return false;
    });

    console.log("Document Title: ", route_title);

    document.title = route_title ? route_title + " - XAPiT" : "XAPiT";
  }, [location]);

  return null;
};

ReactDOM.render(
  <CookiesProvider>
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        use: [cleankeyMiddleware]
      }}
    >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
        <AuthProvider>
          <BrowserRouter>
            <Suspense
                fallback={<div>Loading...</div>}
            >
              <DocumentTitle />
              <ScrollToTop />

              <Routes>
                {routesConfig.map((item) => {
                  if (!item.route) return null; //Skip non-route items

                  if (item.level === "none") {
                    return <Route {...item}></Route>;
                  } else {
                    return (
                      <>
                        <Route
                          {...item}
                          element={
                            <RequireAuth
                              layout={item.layout}
                              children={item.element}
                            ></RequireAuth>
                          }
                        ></Route>
                        {item.subRoutes && item.subRoutes.length > 0
                          ? item.subRoutes.map((subItem) => {
                              return (
                                <Route
                                  {...subItem}
                                  element={
                                    <RequireAuth
                                      layout={
                                        subItem.layout
                                          ? subItem.layout
                                          : item.layout
                                      }
                                      children={subItem.element}
                                    />
                                  }
                                ></Route>
                              );
                            })
                          : null}
                      </>
                    );
                  }
                })}
              </Routes>
            </Suspense>
          </BrowserRouter>
        </AuthProvider>
    </SWRConfig>
  </CookiesProvider>,
  document.getElementById("root"),
);

// if ("serviceWorker" in navigator) {
//   window.addEventListener("load", () => {
//     navigator.serviceWorker.register("/service-worker.js");
//   });
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
