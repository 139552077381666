import { memo, useState } from "react";
import { Carousel } from "react-bootstrap";
import PostItemAttachment from "./PostItemAttachment";

export const AttachmentCarousel = ({ attachments, owner_data }) => {
    const [index, setIndex] = useState(0);
  
    const handleSelect = (selectedIndex) => {
      setIndex(selectedIndex);
    };
  
    return (
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        interval={null}
        variant="dark"
      >
        {attachments.map((item, index) => {
          return (
            <Carousel.Item key={index} style={{}}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <PostItemAttachment
                  attachment={item}
                  key={`post-item-attachment-${index}`}
                  owner_data={owner_data}
                />
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    );
  };

export default memo(AttachmentCarousel);