import { map_type_to_link } from "../../../utils/mapping";

const PageAttachment = ({attachment}) => {
  const attachment_link = map_type_to_link(attachment.attachment_type, attachment.attachment_id)
  return(
    <div className="post-page-attachment">
      <a href={attachment_link}>
      <div className="post-page-attachment-image">
        <img src={attachment.image_main.src}/>
      </div>
      <div className="post-page-attachment-content">
        <div className="post-page-attachment-title">
          <span>{attachment.title}</span>
        </div>
        <div>
          {attachment.body}
        </div>
      </div>
      </a>
    </div>
  )
}

export default PageAttachment;