import React, { useContext, useEffect, useRef, useState } from "react";
import { FeedContext } from "../../../utils/context/feedContext";

const PostItemSettings = ({ action_id, action_type }) => {
  const [show, setShow] = useState(false);
  const menuRef = useRef(null);
  const { handleDelete, handleHidePost } = useContext(FeedContext);

  const toggleMenu = () => {
    setShow(!show);
  };

  const handleDeletePost = async () => {
    handleDelete(action_id);
    setShow(false);
  };
  const _handleHidePost = async () => {
    handleHidePost(action_id, action_type);
    setShow(false); 
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <div className="col-1 post-item-more-btn-container">
      <span
        onClick={toggleMenu}
        style={{
          cursor: "pointer",
        }}
      >
        ...
      </span>
      <div
        className={`post-item-settings-menu ${show ? "show" : "noshow"}`}
        ref={menuRef}
      >
        <div onClick={_handleHidePost}>
          <span>Hide Post</span>
        </div>
        <div onClick={handleDeletePost}>
          <span>Delete Post</span>
        </div>
      </div>
    </div>
  );
};

export default PostItemSettings;