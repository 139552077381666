import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { map_type_to_link } from "../../../utils/mapping";
import { Comments, CreateComment } from "../Comment";
import PostPrivacyIcon from "../PostPrivacyIcon";
import ActivityText from "./ActivityText";
import AttachmentCarousel from "./AttachmentCarousel";
import { PostFooter } from "./PostFooter";
import PostItemAttachment from "./PostItemAttachment";
import PostItemSettings from "./PostItemSettings";

const _PostItem = React.forwardRef(
  ({ postData, handles, commentsOff = false, actionsOff = false }, ref) => {
    const [showCreateComment, toggleComment] = useState(true);
    const [showMore, setShowMore] = useState(false);
    const [isOwner, setIsOwner] = useState(false);

    //Parse Date into more readable format
    let posted_date = new Date(postData.feed.modified_date);
    posted_date = posted_date.toLocaleString("default", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });

    let attachment_exists = postData.feed.attachment_count > 0;

    let tagged_friends_text = null;

    if (postData.feed.tags && postData.feed.tags.length > 0) {
      tagged_friends_text = (
        <>
          {postData.feed.tags.map((tag_item) => {
            return (
              <div style={{ display: "inline" }}>
                <span>tagged </span>
                <a href={map_type_to_link(tag_item.tag_type, tag_item.tag_id)}>
                  <span> {tag_item.tag_obj.displayname}</span>
                </a>
              </div>
            );
          })}
        </>
      );
    }

    const handleCommentToggle = () => {
      toggleComment(!showCreateComment);
    };

    useEffect(() => {
      // Check ownership
      const loggedInUserId = window.localStorage.getItem("user_id");
      if (
        postData?.feed?.subject_type === "user" &&
        postData?.feed?.subject_id == loggedInUserId
      ) {
        setIsOwner(true);
      }

      // Check comment permission
      if (!postData?.canComment) {
        toggleComment(false);
      }
    }, []);

    return (
      <div className="post-item-container" ref={ref} key={`post-${postData.feed.action_id}`}>
        <div className="post-item col">
          <div className="post-item-header row">
            <div className="col-1">
              <Link
                to={`/profile/${postData.feed.subjectIformation.subject_id}`}
                reloadDocument
              >
                <img
                  src={postData.feed.feed_icon}
                  className="post-item-profile-pic"
                  alt=""
                  onError={({ target }) => {
                    target.onerror = null 
                    target.src = "/no_profile_pic.png"
                  }}
                ></img>
              </Link>
            </div>
            <div className="col-8 post-item-info">
              <h5 className="post-item-display-name">
                <Link
                  to={`/profile/${postData.feed.subjectIformation.subject_id}`}
                  reloadDocument
                >
                  {postData.feed.subjectIformation.displayname}
                </Link>
                {postData.feed.action_type_body ? (
                  <span className="post-item-sub-text">
                    {" "}
                    {<ActivityText postData={postData}/>} {tagged_friends_text}
                  </span>
                ) : null}
              </h5>
              <p className="post-item-posted-date">
                <Link to={`/posts/${postData.feed.action_id}`}>
                  {posted_date}
                </Link>{" "}
                <PostPrivacyIcon privacy_type={postData.feed.privacy} />
              </p>
            </div>
            {isOwner ? (
              <PostItemSettings action_id={postData.feed.action_id} action_type={postData.feed.type}/>
            ) : null}
          </div>
          <div className="post-body-container">
            {showMore
              ? postData.feed.body
              : `${postData.feed.body.slice(0, 250)}`}
            {postData.feed.body && postData.feed.body.length > 250 && (
              <button
                className="show-more-less-btn"
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? "Show less" : "Show more"}
              </button>
            )}
          </div>
          {attachment_exists ? (
            postData.feed.attachment.length > 1 ? (
              <AttachmentCarousel attachments={postData.feed.attachment} owner_data={postData.feed.subjectIformation}/>
            ) : (
              <div className="post-body-container-content">
                <PostItemAttachment attachment={postData.feed.attachment[0]} owner_data={postData.feed.subjectIformation}/>
              </div>
            )
          ) : null}
          {actionsOff ? null : (
            <PostFooter
              is_like={postData.is_like}
              action_id={postData.feed.action_id}
              postData={postData}
              action_type={postData.feed.type}
              handles={handles}
              handleCommentToggle={handleCommentToggle}
            />
          )}
        </div>
        {commentsOff ? null : (
          <div className="comment-container">
            {showCreateComment && postData.can_comment === 1 ? (
              <CreateComment action_id={postData.feed.action_id} />
            ) : null}
            <Comments
              action_id={postData.feed.action_id}
              comment_data={postData.comment_data}
            />
          </div>
        )}
      </div>
    );
  },
);

export const PostItem = React.memo(_PostItem);