import { faComment, faShare, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

export const PostFooter = ({
  is_like,
  action_id,
  handles,
  handleCommentToggle,
  postData,
}) => {
  const [isLike, setLike] = useState(is_like ? true : false);

  const { handleLike, handleUnlike } = handles;

  return (
    <div className="row post-item-footer">
      <div
        className={isLike ? "color-blue" : ""}
        onClick={
          isLike
            ? () => {
                handleUnlike(action_id);
                setLike(false);
              }
            : () => {
                handleLike(action_id);
                setLike(true);
              }
        }
      >
        <FontAwesomeIcon icon={faThumbsUp} /> Like
      </div>
      {postData.can_comment === 1 && (
        <div onClick={handleCommentToggle}>
          <FontAwesomeIcon icon={faComment} /> Comment
        </div>
      )}
      {postData.can_share === 1 && (
        <div
          onClick={() => {
            handles.handleShowShare(postData);
          }}
          className=""
        >
          <FontAwesomeIcon icon={faShare} /> Share
        </div>
      )}
    </div>
  );
};