import React from "react";
import reactStringReplace from "react-string-replace";
import { map_type_to_link } from "../../../utils/mapping";
import feelingsConfig from "../postAttachments/activitiesConfig";

const ActivityText = ({ postData }) => {
  let activityText = "";

  if (postData.feed.params.feelings) {
    let parentId = postData.feed.params.feelings.parent;
    let childId = postData.feed.params.feelings.child;
    let parentText = postData.feed.params.feelings.parenttitle;
    let childText = "";

    for (let i = 0; i < feelingsConfig.child[String(parentId)].length; i++) {
      let curItem = feelingsConfig.child[String(parentId)][i];
      if (curItem.id === childId) childText = curItem.title;
      else continue;
    }

    activityText = `is ${parentText} ${childText}`;
  }
  if (
    postData.feed.action_type_body.includes("{item:$subject}") &&
    postData.feed.action_type_body.includes("{item:$object}")
  ) {
    activityText = postData.feed.action_type_body
      .replace("{item:$subject}", "")
      .replace("{body:$body}", "")
      .replace(".", "");
    for (let i = 0; i < postData.feed.action_type_body_params.length; i++) {
      let current_param = postData.feed.action_type_body_params[i];
      if (current_param.search === "{item:$object}") {
        activityText = reactStringReplace(activityText, "{item:$object}", (match, i) => (
          <a href={map_type_to_link(current_param.type, current_param.id)}>{current_param.label}</a>
        ));
      }
      if (current_param.search === "{var:$type}") {
        activityText = reactStringReplace(activityText, "{var:$type}", (match, i) => (
          current_param.label
        ));
      }
    }
  }
  if (activityText) {
    return <span>{activityText}</span>;
  } else {
    return null;
  }
};

export default ActivityText;