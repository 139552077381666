import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton
} from 'react-share';
import { toast } from "react-toastify";
import { shareContent } from "../../utils/api/functions/misc";
import { LoadingButton } from "../buttons/Buttons";
import { AttachmentCarousel } from "./Feedv2";

const ShareButtons = ({url}) => {
    return (
      <div
          style={{
            display: "flex", gap: "10px"
          }}
        >
          <FacebookShareButton url={url}>
            <FacebookIcon />
          </FacebookShareButton>
          <TwitterShareButton url={url}>
            <TwitterIcon />
          </TwitterShareButton> 
          <RedditShareButton url={url}>
            <RedditIcon />
          </RedditShareButton>  
          <EmailShareButton url={url}>
            <EmailIcon />
          </EmailShareButton>
        </div>
    )
}

const ShareItem = ({ data }) => {
  let posted_date = new Date(data.feed.modified_date);
  posted_date = posted_date.toLocaleString("default", {
    month: "short",
    day: "numeric",
  });

  return (
    <div className="share-item">
      <div className="share-item-header">
        <img
          src={data.feed.subjectIformation.image_profile}
          className="post-item-profile-pic"
        />
        <div className="share-item-header-title">
          {data.feed.subjectIformation.displayname}
          <div className="share-item-header-date">{posted_date}</div>
        </div>
      </div>
      <div className="share-item-body">
        {data.feed.body}
        {data.feed.attachment && data.feed.attachment.length > 1 ? (
          <AttachmentCarousel attachments={data.feed.attachment} />
        ) : null}
      </div>
    </div>
  );
};

const Share = ({ show, onHide, data }) => {


  const [body, setBody] = useState("");

  const [loading, setLoading] = useState(false);



  useEffect(() => {
  }, [data]);

  const handleChange = (e) => {
    setBody(e.target.value);
  };

  const handleShare = async () => {
    setLoading(true);

    const _form = new FormData();

    _form.append("type", "activity_action");
    _form.append("id", data.feed.action_id);
    _form.append("body", body);

    const res = await shareContent(_form);

    if(res.status_code === 204) {
      toast.success("Post shared successfully!"); 
    }
    else {
      toast.error("Error sharing post"); 
    }
    setLoading(false);
  };

  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Share</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Share this by re-posting it with your own message</p>
        <div className="share-container">
          <textarea onChange={handleChange} />

          <ShareItem data={data} />
        </div>
        <p>Share To:</p>
        <ShareButtons url={window.location.origin + "/posts/" + data?.feed?.action_id} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <LoadingButton
          text={"Share"}
          variant={"primary"}
          onClick={handleShare}
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default Share;
