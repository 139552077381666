//Comments component
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmojiPicker from "emoji-picker-react";
import React, { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  likeFeedOrComment,
  postCreateComment,
  unlikeFeedOrComment,
} from "../../utils/api/functions/advancedActivityFeeds";
import {
  postCreateCommentBasic,
  usePaginateCommentsBasic
} from "../../utils/api/functions/likesComments";
import { useGetUserInfo } from "../../utils/api/functions/members";
import { FeedContext } from "../../utils/context/feedContext";
import { relativeTimeFormat } from "../../utils/parsing";

 
export const CreateComment = ({
  action_id,
  nested,
  hideProfilePicture = false,
  subject_type = false,
}) => {
  const { data } = useGetUserInfo({
    user_id: window.localStorage.getItem("user_id"),
  });
  // const {data: comment_data, mutate} = useGetLikesCommentsBasic({
  //   subject_id: String(action_id), 
  //   subject_type: String(subject_type), 
  //   viewAllComments: String(1)
  // });  

  const [profilePic, setProfilePic] = useState("");
  const [commentText, setCommentText] = useState("");
  const [sending, setSending] = useState(false);
  const [isFocused, setIsFocused] = useState(false); 
  const [showEmoji, setShowEmoji] = useState(false); 

 

  const { handleUpdate } = useContext(FeedContext);

  useEffect(() => {
    if (data && data.body && data.body.response) {
      setProfilePic(data.body.response.image_icon);
    }
  }, [data]);

  const handleChange = (e) => {
    setCommentText(e.target.value);
  };

  const handleSubmit = async () => {
    if (sending) return;
    setSending(true);
    const _formData = new FormData();

    if (subject_type !== false) {
      _formData.append("subject_type", subject_type);
      _formData.append("subject_id", action_id);
      _formData.append("body", commentText);
      const result = await postCreateCommentBasic(_formData);
      if (result?.status_code === 200) {
        toast.success("Comment created successfully");
        // mutate();
      } else {
        toast.error("Error creating comment");
      }
    } else {
      _formData.append("action_id", action_id);
      _formData.append("body", commentText);
      const result = await postCreateComment(_formData);


      if (result?.status_code === 200) {
        toast.success("Comment created successfully");
        // mutate();
        handleUpdate(action_id, result.body);
        
      } else {
        toast.error("Error creating comment");
      }
    }

    setCommentText("");
    setSending(false);
  };

  const handleOnFocus = () => {
    setIsFocused(true); 
  }
  const handleBlur = (e) => {
    console.log(e)
    console.log(e.relatedTarget); 
    setIsFocused(false)
  }

  const toggleEmojiPicker = (e) => {
    e.preventDefault();
    setShowEmoji(!showEmoji); 
  }

  const onEmojiClick = (e, emojiObject) => {
    setCommentText(commentText + emojiObject.emoji); 
  }

  return (
    <div className={`comment-create ${nested ? "nested" : ""}`}>
      {!hideProfilePicture ? (
        <div>
          <img src={profilePic} />
        </div>
      ) : null}
      <div className="col comment-create-text-box-container"
        
      >
        <textarea
          placeholder="Write a comment..."
          value={commentText}
          onChange={handleChange}
          maxLength={1000}
        ></textarea>
        
        {
          showEmoji ? (<EmojiPicker
            pickerStyle={{
              position: 'absolute',
              zIndex: 9999,
              left: "2rem"
            }}
            onEmojiClick={onEmojiClick}
            disableAutoFocus
          />) : (null)
        }
        
        <div className="comment-create-emoji-picker">
          <span onClick={toggleEmojiPicker} style={{cursor: "pointer"}}>😃</span>
        </div>
        

        {commentText.length > 0 ? <div>{commentText.length}/1000</div> : null}
        <div className="comment-create-text-box-send-btn">
          <FontAwesomeIcon icon={faPaperPlane} onClick={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

const CommentActions = ({ handles, is_like, comment_id, action_id }) => {
  const [isLiked, setIsLiked] = useState(is_like);
  const [showCreateComment, toggleComment] = useState(false);

  const handleToggle = () => {
    toggleComment(!showCreateComment);
  };
  const { handleLike, handleUnlike } = handles;

  return (
    <>
      <div className="comment-item-actions">
        <p>
          <span
            onClick={handleToggle}
            className={showCreateComment ? "comment-item-actions-active" : ""}
          >
            Reply
          </span>
          <span
            className={isLiked ? "comment-is-liked" : ""}
            onClick={
              isLiked
                ? () => {
                    handleUnlike(action_id, comment_id);
                    setIsLiked(false);
                  }
                : () => {
                    handleLike(action_id, comment_id);
                    setIsLiked(true);
                  }
            }
          >
            Like
          </span>
        </p>
      </div>
      {showCreateComment ? <CreateComment nested={true} /> : null}
    </>
  );
};

//Loads in Post feed
export const Comments = ({ comment_data }) => {
  //const {data, mutate} = useGetLikesComments({action_id: String(action_id), viewAllComments: String(1)});
  const [fetchedComments, setComments] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const handleLike = async (post_action_id, comment_id) => {
    let formData = new FormData();
    let postData = {
      action_id: post_action_id,
      comment_id: comment_id,
    };
    for (let key in postData) {
      formData.append(key, postData[key]);
    }
    await likeFeedOrComment(formData);
  };
  const handleUnlike = async (post_action_id, comment_id) => {
    let formData = new FormData();
    let postData = {
      action_id: post_action_id,
      comment_id: comment_id,
    };
    for (let key in postData) {
      formData.append(key, postData[key]);
    }
    await unlikeFeedOrComment(formData);
  };

  useEffect(() => {
    let new_list = [];
    if (!comment_data) return;
    if (comment_data.data && comment_data?.data.length > 0) {
      new_list = [...comment_data.data];
      setComments(() => new_list);
    }
  }, [comment_data]);

  if (!comment_data || comment_data.data === null) {
    return null;
  }

  return (
    <>
      {fetchedComments.map((item) => {
        if (item.isLoading) return <div>BIG TEST</div>;

        return (
          <div className="comment-item" key={`comment-${item.comment_id}`}>
            <div className="col-1 comment-item-profile-pic">
              <Link to={`/profile/${item.poster_id}`}>
                <img src={item.poster_photo}></img>
              </Link>
            </div>
            <div className="col comment-item-body">
              <p className="author-item-name">
                <Link
                  to={`/profile/${item.poster_id}`}
                  className="author-item"
                  reloadDocument
                >
                  {item.poster_displayname}
                </Link>
              </p>
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="tooltip">{(new Date(item.creation_date + " UTC")).toLocaleString()}</Tooltip>}
              >
                <p style={{
                  fontSize: "0.7rem",
                  display: "inline-block"
                }}>
                  {relativeTimeFormat(new Date(item.creation_date + " UTC"))}
                </p>
              </OverlayTrigger>
              
              <p className="comment-item-text">
                {showMore ? item.body : `${item.body.slice(0, 250)}`}
                {item.body && item.body.length > 250 && (
                  <button
                    className="show-more-less-btn"
                    onClick={() => setShowMore(!showMore)}
                  >
                    {showMore ? "Show less" : "Show more"}
                  </button>
                )}
              </p>
              <CommentActions
                handles={{
                  handleLike: handleLike,
                  handleUnlike: handleUnlike,
                }}
                is_like={item?.like?.isLike}
                action_id={item.resource_id}
                comment_id={item.comment_id}
                item={item}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

const LoadMoreComments = ({_loadMore}) => {

  return(
    <div onClick={_loadMore}
        style={{
          cursor: 'pointer'
        }}
      >
        <span>Load More Comments</span>
    </div>
  )
}

export const BasicComments = ({ subject_id, subject_type }) => {
  const { data, mutate, size, setSize } = usePaginateCommentsBasic({
    subject_id: String(subject_id),
    subject_type: String(subject_type),
    viewAllComments: String(1),
  });
  const [fetchedComments, setComments] = useState([]);
  const [totalComments, setTotalComments] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const likeFn = async (action_id, comment_id, updated_object) => {
    let formData = new FormData();
    let postData = {
      action_id: action_id,
      comment_id: comment_id,
    };
    for (let key in postData) {
      formData.append(key, postData[key]);
    }
    let res = await likeFeedOrComment(formData);
    if (res.status_code == 204 || res.status_code == 200) {
      return { ...updated_object };
    } else {
      throw new Error("Invalid API Response");
    }
  };
  const unlikeFn = async (action_id, comment_id, updated_object) => {
    let formData = new FormData();
    let postData = {
      action_id: action_id,
      comment_id: comment_id,
    };
    for (let key in postData) {
      formData.append(key, postData[key]);
    }
    let res = await unlikeFeedOrComment(formData);
    if (res.status_code == 204 || res.status_code == 200) {
      return { ...updated_object };
    } else {
      throw new Error("Invalid API Response");
    }
  };
  const handleLike = async (action_id, comment_id) => {
    let updated_object = new Object({ ...data });
    for (let i = 0; i < updated_object.body.viewAllComments.length; i++) {
      let item = updated_object.body.viewAllComments[i];
      if (item.comment_id == comment_id) {
        updated_object.body.viewAllComments[i].like.isLike = 1;
        break;
      }
    }

    mutate(likeFn(action_id, comment_id, updated_object), {
      optimisticData: {
        ...updated_object,
        body: {
          ...updated_object.body,
          ...updated_object.body.viewAllComments,
        },
      },
      rollbackOnError: true,
      populateCache: true,
      revalidate: false,
    });
  };
  const handleUnlike = async (action_id, comment_id) => {
    let updated_object = new Object({ ...data });

    for (let i = 0; i < updated_object.body.viewAllComments.length; i++) {
      let item = updated_object.body.viewAllComments[i];
      if (item.comment_id == comment_id) {
        updated_object.body.viewAllComments[i].like.isLike = 0;
        break;
      }
    }

    await mutate(unlikeFn(action_id, comment_id, updated_object), {
      optimisticData: { 
        ...updated_object,
        body: {
          ...updated_object.body,
          ...updated_object.body.viewAllComments,
        },
      },
      rollbackOnError: true,
      populateCache: true,
      revalidate: false,
    });
  };

  const _loadMore = () => {
    setSize(size+1); 
  }

  useEffect(() => {
    //Clear comments before fetching new ones (triggers on component mount)
    setComments(() => []);

    let new_list = [];
    console.log("COMMENT DATA: ", data)
    if(!data) return; 
    for(let i = 0; i<data.length; i++){
      if(!data[i].body) return
      new_list = [...new_list, ...data[i].body.viewAllComments]
    }
    setComments(() => new_list);
    setTotalComments(data.slice(-1)[0].body.getTotalComments); 
    // if (
    //   data &&
    //   data.body &&
    //   data.body.viewAllComments &&
    //   data.body.viewAllComments.length > 0
    // ) {
    //   new_list = [...data.body.viewAllComments];
    //   setComments(() => new_list);
    // }
  }, [data]);

  return (
    <>
      {fetchedComments.map((item) => {
        return (
          <div className="comment-item">
            <div className="col-1 comment-item-profile-pic">
              <Link to={`/profile/${item.user_id}`}>
                <img src={item.author_image_icon}></img>
              </Link>
            </div>
            <div className="col comment-item-body">
              <p className="author-item-name">
                <Link to={`/profile/${item.user_id}`} className="author-item">
                  {item.author_title}
                </Link>
              </p>
              <p className="lightbox-comment-body">
                {showMore ? item.comment_body :`${item.comment_body.slice(0, 250)}`}
                {item.comment_body && item.comment_body.length > 250 && (
                  <button
                    className="show-more-less-btn"
                    onClick={() => setShowMore(!showMore)}
                  >
                    {showMore ? "Show less" : "Show more"}
                  </button>
                )}
              </p>
              <CommentActions
                handles={{
                  handleLike: handleLike,
                  handleUnlike: handleUnlike,
                }}
                is_like={item.like.isLike}
                action_id={item.action_id}
                comment_id={item.comment_id}
              />
            </div>
          </div>
        );
      })}
      {
        fetchedComments.length !== 0 && fetchedComments.length < totalComments ? (<LoadMoreComments _loadMore={_loadMore}/>) : null
      }
      
    </>
  );
};
