/* 
Data Mapping Functions 

Functions in this file map values from the database and REST API to it's respective value for the site. 
( May be temporary until the REST API is reworked )
*/

//Brackets are used as placeholders
const notification_type_map = {
  activity_action: "/posts/{object_id}",
  activity_comment: "/posts/{object_id}",
  //"album": "/albums/{id}",
  //"album_photo": "/albums/{id}",
  event: "/events/{subject_id}",
  group: "/groups/{subject_id}",
  // "group_photo": "/groups/{id}",
  messages_conversation: "/messages/{id}",
  sitepage_page: "/pages/{id}",
  sitepage: "/pages/{id}",
  //"sitepage_album": "/pages/{id}/albums/{id}"
  sitepage_photo: "/pages/{id}/albums/{id}/photo/{id}",
  user: "/profile/{subject_id}",
  video_processed: {
    //"/profile/{subject_id}/videos/{object_id}", //Video processed (user profile)
  },
  video: {
    user: "/profile/{subject_id}/videos/{object_id}", //Video uploaded (user profile)
    group: "/groups/{parent_id}/videos/{object_id}", //Video uploaded (group)
    sitepage: "/pages/{subject_id}/videos/{object_id}", //Video uploaded (sitepage)
  },
  friend_accepted: "/profile/{subject_id}",
  friend_request: "/profile/{subject_id}",
};
export const map_notification_to_link = (notification) => {
  const object_type = notification.object_type;
  const object_id = notification.object_id;
  const subject_id = notification.subject_id;
  if (notification_type_map[object_type]) {
    if (object_type === "video") {
      // Check if video is from a user
      if (
        notification.type === "video_processed" &&
        notification.object.parent_type === null
      ) {
        //Check if video is an object from a user
        let unparsed_link = notification_type_map[object_type]["user"];
        return unparsed_link
          .replace("{subject_id}", subject_id)
          .replace("{object_id}", object_id);
      }

      // Check if video is from a group
      else if (
        notification.type === "sitevideo_processed" &&
        notification.object.parent_type === "group"
      ) {
        let unparsed_link = notification_type_map[object_type]["group"];
        return unparsed_link
          .replace("{parent_id}", notification.object.parent_id)
          .replace("{object_id}", object_id);
      }

      // TODO: Check if video is from a sitepage
      return false;
    }

    return notification_type_map[object_type]
      .replace("{object_id}", object_id)
      .replace("{subject_id}", subject_id);
  }

  return false;
};

const type_to_link_map = {
  sitepage: "/pages/page/{id}",
  sitepage_page: "/pages/page/{id}",
  group: "/groups/group/{id}",
  user: "/profile/{id}",
  event: "/events/event/{id}",
};

export const map_type_to_link = (type, id) => {
  if (type_to_link_map[type]) {
    return type_to_link_map[type].replace("{id}", id);
  }
  return false;
};
