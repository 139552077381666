import React, { useRef, useState } from "react";
import FilterButton from "../../components/posts/buttons/Filter";
//Import components
import CreatePost from "../../components/posts/CreatePost";
// import PostsFeed from '../../components/posts/PostsFeed';
import PostsFeed from "../../components/posts/feed/FeedV3";
import PopularPages from "../../components/sidebar/PopularPages";
//Import Sidebar components
import Donate from "../../components/sidebar/Donate";
import ProfilePreview from "../../components/sidebar/ProfilePreview";
import QuickLinks from "../../components/sidebar/QuickLinks";
//Import FontAwesome icons
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

import AdsWidget from "../../components/widgets/AdsWidget";
import { usePaginateAdvancedActivityFeeds } from "../../utils/api/functions/advancedActivityFeeds";
import usePostsStore from "../../utils/stores/postsStore";

import TimelineNavigation, { TimelineNavigationToggle } from "../../components/sidebar/timeline/TimelineNavigation";
import StoriesWidget from "../../components/widgets/StoriesWidget/StoriesWidget";

const Home = () => {
  const [feedParams, setFeedParams] = useState({
    filter_type: "all",
  });  

  const [toggleTimeline, setToggleTimeline] = useState(false); 

  const postsListRef = usePostsStore((state) => state.postsListRef);
  const sidebarLeftRef = useRef(null);
  const sidebarRightRef = useRef(null);
  const containerRef = useRef(null);
  const targetRef = useRef(null);

  const handleFilterChange = (filter_type) => {
    setFeedParams({
      ...feedParams,
      filter_type: filter_type,
    });
  };

  const handleTimelineChange = (range) => {
    setFeedParams({
      ...feedParams,
      timeline_start: range[0],
      timeline_end: range[1],
    });
  }


  const {data, isLoading, mutate, size, setSize} = usePaginateAdvancedActivityFeeds(feedParams); 

  const CreatePostComponent = ({ callMutate }) => {
    return (
      <>
        <CreatePost title={"My News Feed"} callMutate={callMutate} />
        <div className="news-feed-buttons-col col-12">
          <div className="news-feed-buttons">
            <FilterButton
              text="Customize News Feed"
              handleChange={handleFilterChange}
              feedParams={feedParams}
            />
          </div>
        </div>
      </>
    );
  };

  const handleScroll = (event) => {
    if(postsListRef.current && targetRef.current){
      targetRef.current.scrollTo({
        top: postsListRef.current.state.scrollOffset + event.deltaY*6,
        behavior: "smooth"
      })
    }
  };

  const handleTimelineToggle = () => {
    setToggleTimeline(!toggleTimeline);
  }

  return (
    <div
      className="home-container"
      ref={containerRef}
      style={{
        scrollBehavior: "smooth",
      }}
    >
      <div className="home-sidebar-container sidebar-left" onWheel={handleScroll}>
        <div
          className="home-sidebar layout-left"
          ref={sidebarLeftRef}
          style={{ transitionProperty: "all", transitionDuration: "0.5s" }}
        >
          {
            toggleTimeline ? (
            <>
              <TimelineNavigationToggle handleToggle={handleTimelineToggle} toggleState={true}/>
              <TimelineNavigation handleChange={handleTimelineChange}/>
            </>
            ) : (
            <>
              <ProfilePreview />
              <Donate />
              <QuickLinks />
              <TimelineNavigationToggle handleToggle={handleTimelineToggle} toggleState={false}/>
            </>
            )
          }
          <div className="right-column-left">
            <PopularPages />
            <AdsWidget />
          </div>
        </div>
      </div>
      <div className="home-news-feed-container">
        <StoriesWidget />
        <PostsFeed
          CreatePostComponent={CreatePostComponent}
          outerRef={targetRef}
          feed_params={feedParams}
        />
      </div>
      <div className="home-sidebar-container"
        onWheel={handleScroll}
      >
        <div
          className="home-sidebar column-right"
          ref={sidebarRightRef}
          style={{ transitionProperty: "all", transitionDuration: "0.5s" }}
        >
          <PopularPages />
          <AdsWidget />
        </div>
        <div className="scroll-button">
          <Button
            variant="primary"
            onClick={() => {
              targetRef.current.scroll({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <FontAwesomeIcon icon={faArrowUp} />
          </Button>
        </div>
      </div>
      <div className="scroll-button-mini">
          <Button
            variant="primary"
            onClick={() => {
              targetRef.current.scroll({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <FontAwesomeIcon icon={faArrowUp} />
          </Button>
        </div>
    </div>
  );
};

export default Home;
