import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  faBell,
  faCheck,
  faEllipsis,
  faEnvelope,
  faGear,
  faMagnifyingGlass,
  faTrashAlt,
  faUserPlus,
  faXmark
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
//import navigation row
import LogoImgSm from "../../assets/xapit-mini-logo.png";
import LogoImg from "../../assets/xapit_logo_135.png";
import Navigation from "./Navigation";
import SideNav from "./SideNav";
//Virtualized List Widget imports
import TailSpin from "react-loading-icons/dist/esm/components/tail-spin";
import { toast } from "react-toastify";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import {
  postAcceptFriendRequest,
  postRejectFriendRequest
} from "../../utils/api/functions/friends";
import { useGetMessages } from "../../utils/api/functions/messages";
import { postGlobalSearch } from "../../utils/api/functions/misc";
import {
  postMarkAllNotificationsRead,
  useGetFriendsRequests,
  useGetNotifications
} from "../../utils/api/functions/notification";
import useAuth from "../../utils/authentication";
import { useMessages } from "../../utils/context/messages/messagesContext";
import { map_notification_to_link } from "../../utils/mapping";
import { getMonthAndDate } from "../../utils/parsing";


const FriendsListItem = ({ data, index, style, handleRemoveSelf }) => {
  let item_data = data[index];
  

  const AcceptRequest = async () => {
    const formData = new FormData();
    formData.append("user_id", item_data.subject.user_id);

    const res = await postAcceptFriendRequest(formData);

    if (res.status_code === 204) {
      toast.success("Friend request accepted!"); 
      handleRemoveSelf(index); 
    }
    else {
      toast.error("Something went wrong!"); 
    }
  };

  const RejectRequest = async () => {
    const formData = new FormData();
    formData.append("user_id", item_data.subject.user_id);

    const res = await postRejectFriendRequest(formData);

    if (res.status_code === 204){
      toast.success("Friend request rejected!"); 
      handleRemoveSelf(index)
    } else {
      toast.error("Something went wrong!"); 
    }
  };

  return (
    <div className="list-widget-friend-item" style={style}>
      <div className="widget-friend-item-date">
        {getMonthAndDate(item_data.date)}
      </div>
      <div className="widget-friend-item-content">
        <div className="widget-friend-item-profile">
          <img src={item_data.subject.image_profile} alt="" />
        </div>
        <div className="widget-friend-item-desc">
          <div>
            <Link to={`/profile/${item_data.subject.user_id}`}>
              {item_data.subject.displayname}
            </Link>{" "}
            has requested to be your friend
          </div>
          <div className="widget-friend-item-actions">
            <Button variant="primary" size="sm" onClick={AcceptRequest}>
              Accept
            </Button>
            <Button variant="danger" size="sm" onClick={RejectRequest}>
              Reject
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
const CountBadge = ({ count }) => {
  return <div className="count-badge">{count > 99 ? "99+" : count}</div>;
};
const ListWidget = ({
  title = null,
  footer = null,
  icon,
  scroll = true,
  children,
  badgeCount = 0,
}) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  const handleClick = () => {
    setShow(!show);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShow(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);
  return (
    <div ref={ref} className="header-widget-action">
      <div className="header-widget-icon">
        {badgeCount > 0 ? <CountBadge count={badgeCount} /> : null}
        <FontAwesomeIcon icon={icon} onClick={handleClick} />
      </div>

      <div
        className={`header-list-widget-container ${show ? "active" : null} ${
          scroll ? "content-scroll" : null
        }`}
      >
        {title ? <div className="list-widget-header">{title}</div> : null}
        <div
          className={`list-widget-content ${scroll ? "content-scroll" : null}`}
        >
          {/* <AutoSizer>
                        {
                            ({height, width}) => (
                                <List
                                    className="List"
                                    height={height}
                                    itemCount={5}
                                    itemSize={100}
                                    width={width}
                                >
                                    {ListWidgetItem}
                                </List>
                            )
                        }
                    </AutoSizer> */}
          {children}
        </div>
        {footer ? <div onClick={() => setShow(false)} className="list-widget-footer">{footer}</div> : null}
      </div>
    </div>
  );
};

const FriendRequestFooter = ({mutate}) => {
  return (
    <div className="friend-requests-footer">
      <Link to="/notifications">View All Requests</Link>
    </div>
  );
};

const FriendRequests = () => {
  const [requests, setRequests] = useState([]);
  const [badgeCount, setBadgeCount] = useState(0);

  const { data, isLoading, mutate } = useGetFriendsRequests({});

  useEffect(() => {
    if (data && data.body) {
      data.body.response ? setRequests(() => [...data.body.response]) : setRequests(() => []);
      setBadgeCount(data.body.totalItemCount);
    }
  }, [data]);

  const handleRemoveSelf = (index) => {
    mutate({
      ...data,
      body: {
        ...data.body,
        response: data.body.response.filter((_, i) => i !== index),
        totalItemCount: data.body.totalItemCount - 1 
      }
    }); 
  }

  return (
    <ListWidget
      title="Friend Requests"
      icon={faUserPlus}
      badgeCount={badgeCount}
      footer={<FriendRequestFooter />}
    >
      {isLoading ? (
        <div className="loading-icon-wrapper">
          <TailSpin stroke="#00BCD4" fill="#00BCD4" />
        </div>
      ) : (
        <>
          {requests.length === 0 ? (
            <div className="loading-icon-wrapper">No Friend Requests...</div>
          ) : (
            <AutoSizer>
              {({ height, width }) => {
                return (
                  <List
                    className="List"
                    height={height}
                    width={width}
                    itemCount={requests.length}
                    itemSize={110}
                    itemData={requests}
                  >
                    {({index, data, style}) => (
                      <FriendsListItem
                        index={index}
                        data={data}
                        style={style}
                        handleRemoveSelf={handleRemoveSelf}
                      />
                    )}
                  </List>
                );
              }}
            </AutoSizer>
          )}
        </>
      )}
    </ListWidget>
  );
};

const MessagesFooter = () => {
  return <Link to="/messages">View All Messages</Link>;
};
const MessageItem = ({ data, index, style, handleClick }) => {
  let item_data = data[index];

  const [showActions, setShowActions] = useState(false);

  const truncate = (input) =>
    input?.length > 60 ? `${input.substring(0, 55)}...` : input;

  return (
    <div className="widget-message-item" style={style}>
      <div className="widget-message-item-image">
        <img alt="" src={item_data.sender.image_icon} />
      </div>
      <div
        className="widget-message-item-content"
        onClick={() => {
          handleClick(item_data);
        }}
      >
        <div className="widget-message-item-content-name">
          {item_data.sender.displayname}
        </div>
        <div className="widget-message-item-content-body">
          {truncate(item_data.message.body)}
        </div>
      </div>
      <div className="widget-message-item-actions">
        <div className="widget-message-item-actions-icon">
          <FontAwesomeIcon
            icon={faEllipsis}
            onClick={() => {
              setShowActions(!showActions);
            }}
          />

          <div
            className={`widget-message-item-actions-menu ${
              showActions ? "show-menu" : ""
            }`}
          >
            <div className="widget-message-item-actions-menu-item">
              <FontAwesomeIcon icon={faTrashAlt} />
              <span>Delete</span>
            </div>
            <div className="widget-message-item-actions-menu-item">
              <FontAwesomeIcon icon={faCheck} />
              <span>Mark as Read</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const [badgeCount, setBadgeCount] = useState(0);
  const { data } = useGetMessages({});
  const {openMessage} = useMessages();
  //Message box pop-up
  const [messageBoxData, setMessageBoxData] = useState({});
  const [showMessageBox, setShowMessageBox] = useState(false);

  useEffect(() => {
    if (data && data.body && data.body.response) {
      setMessages(() => [...data.body.response]);
      setBadgeCount(data.body.getUnreadMessageCount);
    }
  }, [data]);

  const handleMessageClick = (message_data) => {
    // setMessageBoxData(message_data);
    openMessage(message_data);
  };
  const handleHideMessageBox = () => {
    setShowMessageBox(false);
  };

  return (
    <>
      <ListWidget
        title="Messages"
        icon={faEnvelope}
        footer={<MessagesFooter />}
        badgeCount={badgeCount}
      >
        <AutoSizer>
          {({ height, width }) => (
            <List
              className="List"
              height={height}
              width={width}
              itemCount={messages.length}
              itemSize={75}
              itemData={messages}
            >
              {({ index, style, data }) => (
                <MessageItem
                  index={index}
                  data={data}
                  style={style}
                  handleClick={handleMessageClick}
                />
              )}
            </List>
          )}
        </AutoSizer>
      </ListWidget>
      
    </>
  );
};

const NotificationsFooter = ({mutate}) => {
  
  const handleMarkAllAsRead = async () => {
    const res = await postMarkAllNotificationsRead({});
    console.log("mark all read: ", res)
    if(res.status_code === 204) {
      toast.success("Notifications marked as read"); 
      mutate(); 
    }
    else {
      toast.warning("Error marking notifications as read"); 
    }
  };

  return (
    <div className="widget-notifications-footer">
      <Link to="/notifications">View All Notifications</Link>
      <span onClick={handleMarkAllAsRead}>Mark All As Read</span>
    </div>
  );
};

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [badgeCount, setBadgeCount] = useState(0);
  const { data, mutate } = useGetNotifications({
    limit: 10,
  });

  useEffect(() => {
    if (data && data.body && data.body.recentUpdates) {
      setNotifications(() => [...data.body.recentUpdates]);

      setBadgeCount(data.body.notificationsCount);
    }
  }, [data]);

  return (
    <ListWidget
      title="Notifications"
      icon={faBell}
      footer={<NotificationsFooter mutate={mutate}/>}
      badgeCount={badgeCount}
    >
      <AutoSizer>
        {({ height, width }) => (
          <List
            className="List"
            height={height}
            width={width}
            itemCount={notifications.length}
            itemSize={75}
          >
            {({ index, style }) => {
              const item_data = notifications[index];

              if (!item_data) {
                // Log index of item that is null

                return null;
              }

              // Map notification type to link
              const mapped_link = map_notification_to_link(item_data);

              if (mapped_link) {
                return (
                  <Link
                    to={mapped_link}
                    className="widget-notification-item"
                    style={style}
                  >
                    <div className="widget-notification-item-image">
                      <img src={item_data.subject.image_icon} alt="" />
                    </div>
                    <div className="widget-notification-item-content">
                      <div>{item_data.feed_title}</div>
                      <div>{getMonthAndDate(item_data.date)}</div>
                    </div>
                  </Link>
                );
              }
              return (
                <div className="widget-notification-item" style={style}>
                  <div className="widget-notification-item-image">
                    <img src={item_data.subject.image_icon} alt="" />
                  </div>
                  <div className="widget-notification-item-content">
                    <div>{item_data.feed_title}</div>
                    <div>{getMonthAndDate(item_data.date)}</div>
                  </div>
                </div>
              );
            }}
          </List>
        )}
      </AutoSizer>
    </ListWidget>
  );
};
const Settings = () => {

  const {logout} = useAuth(); 
  const navigate = useNavigate(); 

  const handleSignout = async() => {
    await logout(); 
    toast.success("Successfully logged out!");
    navigate("/");
  }

  return (
    <ListWidget icon={faGear} scroll={false}>
      <div className="header-list-widget-settings">
        <Link to="/profile/settings">General</Link>

        <Link to="/profile/settings/privacy">Privacy</Link>

        <Link to="/profile/settings/notifications">Notifications</Link>

        {/* <Link to="/profile/settings/subscriptions">Subscriptions</Link> */}

        <Link to="/profile/settings/change-password">Change Password</Link>
        <div onClick={handleSignout}>Sign Out</div>
      </div>
    </ListWidget>
  );
};

const SearchPreviewItem = ({ item_data, clearSearch }) => {
  const navigate = useNavigate();
  const [itemLink, setLink] = useState("");

  //Generate link based on item_data.type
  useEffect(() => {
    switch (item_data.type) {
      case "User":
        setLink(`/profile/${item_data.user_id}`);
        break;
      case "Group":
        setLink(`/groups/group/${item_data.group_id}`);
        break;
      case "Sitepage":
        setLink(`/pages/page/${item_data.page_id}`);
        break;
      default:
        break;
    }
  }, [item_data]);

  const handleClick = () => {
    if (itemLink) {
      clearSearch();
      navigate(itemLink, { replace: true });
      window.location.reload();
    }
  };

  return (
    <div
      className="default-header-utility-search-preview-item"
      onClick={handleClick}
    >
      <div className="search-preview-item-image">
        <img alt="" src={item_data.image_icon} />
      </div>
      <div className="search-preview-item-content">
        <div className="search-preview-item-title">
          {item_data.title || item_data.displayname}
        </div>
        <div className="search-preview-item-type">{item_data.type}</div>
      </div>
    </div>
  );
};

const SearchPreview = ({ param, handleClear }) => {
  const [items, setItems] = useState([]);
  const [previewActive, setPreviewActive] = useState(false);

  const previewRef = useRef(null);

  useEffect(() => {
    const getResults = async () => {
      const form = new FormData();
      form.append("query", param);
      const data = await postGlobalSearch(form);

      if (data && data.body && data.body.result) {
        setItems(() => [...data.body.result].slice(0, 5));
      }
    };

    if (param.length > 0) {
      setPreviewActive(true);
    } else {
      setPreviewActive(false);
    }

    getResults();
  }, [param]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (previewRef.current && !previewRef.current.contains(e.target)) {
        setPreviewActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`default-header-utility-search-preview ${
        previewActive ? "active" : ""
      }`}
      ref={previewRef}
    >
      {items.length > 0
        ? items.map((item, index) => {
            return (
              <SearchPreviewItem
                key={index}
                item_data={item}
                clearSearch={handleClear}
              />
            );
          })
        : null}
    </div>
  );
};

export const Search = () => {
  const [search, setSearch] = useState("");

  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const clearSearch = () => {
    setSearch(() => "");
  };

  return (
    <div className="default-header-utility-search">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Form.Control
          value={search}
          onChange={handleChange}
          type="search"
          placeholder="Search..."
          className="default-header-utility-search-input"
        />
        <div className="default-header-utility-search-input-icon">
          <FontAwesomeIcon icon={faMagnifyingGlass} />
          
          {search.length > 0 ? (
            <FontAwesomeIcon
              icon={faXmark}
              style={{ marginLeft: "0.5rem" }}
              onClick={clearSearch}
            />
          ) : null}
        </div>
      </Form>
      <SearchPreview param={search} handleClear={clearSearch} />
    </div>
  );
};

const Header = () => {
  return (
    <div>
      <Col className="default-header-container">
        <Row className="default-header-invite-friends">
          <Link target={"_blank"} to="/invite">
            <FontAwesomeIcon icon={solid("hand-point-right")} /> Invite Your
            Friends! <FontAwesomeIcon icon={solid("hand-point-left")} />
          </Link>
        </Row>
        <Row className="default-header-utility">
          <SideNav />
          <div className="default-header-logo-container">
            <Link to="/home">
              <img alt="" src={LogoImg}></img>
            </Link>
          </div>
          <div className="small-header-logo-container">
            <Link to="/home">
              <img alt="" src={LogoImgSm}></img>
            </Link>
          </div>
          <Search />
          <div className="default-header-utility-actions">
            <FriendRequests />
            <Messages />
            <Notifications />
            <Settings />
          </div>
        </Row>
        <Navigation />
      </Col>
    </div>
  );
};

export default Header;
