//This file hosts functions that make some data more readable. I.E: date objects

//Date parsing

export const dateIsToday = (date) => {
  let now = new Date();

  return (
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  );
};
export const dateIsYesterday = (date) => {
  let now = new Date();

  return (
    date.getDate() === now.getDate() - 1 &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  );
};
export const dateIsTomorrow = (date) => {
  let now = new Date();

  return (
    date.getDate() === now.getDate() + 1 &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  );
};

export const getReadableDate = (date) => {
  let readable = "";
  if (dateIsToday(date)) {
    readable = "Today";
  } else if (dateIsYesterday(date)) {
    readable = "Yesterday";
  } else if (dateIsTomorrow(date)) {
    readable = "Tomorrow";
  }

  if (readable) {
    readable +=
      " at " +
      date.toLocaleTimeString("default", {
        hour: "numeric",
        hour12: true,
        minute: "2-digit",
      });
    return readable;
  }
  //Normal Parsing
  else {
    readable =
      date.toLocaleString("default", { month: "short", day: "numeric" }) +
      " at " +
      date.toLocaleTimeString("default", {
        hour: "numeric",
        hour12: true,
        minute: "2-digit",
      });
    return readable;
  }
};

export const getMonthAndDate = (date) => {
  date = new Date(date);
  return date.toLocaleString("default", { month: "short", day: "numeric", year: "numeric" });
};

const TIME_DIVISIONS = [
  { amount: 60, name: "seconds" },
  { amount: 60, name: "minutes" },
  { amount: 24, name: "hours" },
  { amount: 7, name: "days" },
  { amount: 4.34524, name: "weeks" },
  { amount: 12, name: "months" },
  { amount: Number.POSITIVE_INFINITY, name: "years" },
]
const formatter = new Intl.RelativeTimeFormat(undefined, {
  numeric: "auto"
})

export const relativeTimeFormat = (date) => {
  let duration = (date - new Date()) / 1000

  for (let i = 0; i < TIME_DIVISIONS.length; i++) {
    const division = TIME_DIVISIONS[i]
    if (Math.abs(duration) < division.amount) {
      return formatter.format(Math.round(duration), division.name)
    }
    duration /= division.amount
  }
}

export const convertTimezone = (date, timezone) => {
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timezone: timezone}))
}


export const formatDateWithTimezone = (date) => {
  // Convert to ISO string without milliseconds and replace 'T' with space
  let formattedDate = date.toISOString().slice(0, 19).replace('T', ' ');

  // Get timezone offset in minutes and convert to hours and minutes
  const timezoneOffset = date.getTimezoneOffset();
  const absOffset = Math.abs(timezoneOffset);
  const offsetHours = String(Math.floor(absOffset / 60)).padStart(2, '0');
  const offsetMinutes = String(absOffset % 60).padStart(2, '0');

  // Determine if the offset is positive or negative
  const timezoneSign = timezoneOffset > 0 ? '-' : '+';

  // Append the timezone to the formatted date
  return `${formattedDate}${timezoneSign}${offsetHours}:${offsetMinutes}`;
}