import { debounce } from "lodash-es";
import React, { useEffect, useRef } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import usePostsStore from "../../../utils/stores/postsStore";
import LoadingPostItem from "../../loading-placeholders/LoadingPostItem";
import { PostItem } from "./PostItem";

const VirtualizedFeedList = ({
  postData,
  handles,
  loadMoreItems,
  isLoadingMore,
  CreatePostEl = null,
  _isLoading = false,
  ...props
}) => {
  const listRef = useRef({});
  const rowHeights = useRef({});
  const infiniteRef = useRef({});
  const setPostsListRef = usePostsStore((state) => state.setPostsListRef);
  const getRowHeight = (index) => {
    return rowHeights.current[index] + 8 || 80;
  };

  const setRowHeight = (index, size) => {
    rowHeights.current = { ...rowHeights.current, [index]: size };

    listRef.current.resetAfterIndex(0);
  };

  const Row = ({ index, style }) => {
    const rowRef = useRef(null);
    const observerRef = useRef(null); // ✅ Create a stable observer reference
  
    useEffect(() => {
      if (rowRef.current) {
        // ✅ Debounced function to prevent frequent updates
        const debouncedResize = debounce(() => {
          if (rowRef.current) {
            setRowHeight(index, rowRef.current.clientHeight);
          }
        }, 100); // ✅ Adjust debounce time as needed
  
        observerRef.current = new ResizeObserver(debouncedResize);
        observerRef.current.observe(rowRef.current);
      }
  
      return () => {
        if (observerRef.current) {
          observerRef.current.disconnect();
        }
      };
    }, []);
  
    let postElement;
    if (!_isItemLoaded(index)) {
      postElement = (
        <div ref={rowRef} className="post-item-container-loading" key={`post-item-${index}`}>
          {isLoadingMore || _isLoading ? <LoadingPostItem /> : "No more posts to load"}
        </div>
      );
    } else if (index === 0 && CreatePostEl) {
      postElement = (
        <div ref={rowRef} key={`post-item-${index}`}>
          <CreatePostEl />
        </div>
      );
    } else {
      postElement = (
        <PostItem ref={rowRef} postData={postData[index]} key={`post-item-${index}`} handles={handles} style={style} />
      );
    }
  
    return <div style={{ ...style }} key={`post-item-container-${index}`}>{postElement}</div>;
  };

  const _itemCount = postData.length + 1;
  const _isItemLoaded = (index) => index < postData.length;

  // loadMoreItems wrapper to debounce parallel requests
  const _loadMoreItems = isLoadingMore ? () => {} : loadMoreItems;

  useEffect(() => {
    if (postData.posts && postData.length > 0) {
    }
  }, []);
  useEffect(() => {}, [postData]);

  useEffect(() => {
    let isMounted = true;

    const updateRef = async () => {
      try {
        if (isMounted) {
          setPostsListRef(listRef);
        }
      } catch (error) {
        console.error("Error setting posts list ref: ", error);
      }
    };
    updateRef();
    return () => {
      isMounted = false;
    };
  }, [setPostsListRef]);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <InfiniteLoader
          ref={infiniteRef}
          isItemLoaded={_isItemLoaded}
          itemCount={_itemCount}
          loadMoreItems={_loadMoreItems}
          threshold={5}
        >
          {({ onItemsRendered, ref }) => (
            <List
              className="posts-list virtual-list-no-scrollbars"
              height={height}
              width={width}
              itemCount={_itemCount}
              itemSize={getRowHeight}
              onItemsRendered={onItemsRendered}
              ref={(el) => {
                ref(el);
                listRef.current = el;
              }}
              overscanCount={19}
              {...props}
            >
              {Row}
            </List>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
};

export default VirtualizedFeedList;